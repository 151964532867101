import { forwardRef, MouseEventHandler, ReactNode } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

// refactor small and smaller to be size = string
// and square and circle to variant = string
const sizes = {
  square: {
    large: "p-4",
    medium: "p-3",
    small: "p-2",
    smaller: "p-1",
  },
  default: {
    large: "px-8 py-4",
    medium: "px-6 py-3",
    small: "px-4 py-2",
    smaller: "px-2 py-1",
  },
};

const variants = {
  "primary-theme":
    "text-button-text disabled:text-button-text/20 bg-accent hover:bg-accent-90 active:bg-accent-80 disabled:bg-foreground/7",
  "secondary-theme":
    "text-button-text disabled:text-button-text/20 bg-accent/50 hover:bg-accent/40 active:bg-accent/30  disabled:bg-background/5",
  "ghost-theme":
    "text-foreground disabled:text-foreground/20 bg-foreground/7 hover:bg-foreground/20 active:bg-foreground/40  disabled:bg-background/5",
  "cancel-theme":
    "bg-foreground/7 text-error  hover:text-button-text hover:bg-error/70 active:bg-error/50",
  primary:
    "bg-action-700 hover:bg-action-600 active:bg-action-500 text-black-ink",
  lightPrimary: "bg-action-900 text-action-500",
  dark: "bg-grey-100 hover:bg-grey-250 active:bg-action-500 text-grey-900",
  darkAction:
    "bg-action-500 hover:bg-action-400 active:bg-action-300 text-white",
  white: "bg-white hover:bg-grey-950 active:bg-grey-500 text-black-ink",
  peach: "bg-peach-600 hover:bg-peach-500 active:bg-peach-600 text-white",
  whitePeach:
    "bg-grey-950 hover:bg-action-900 active:bg-action-700 text-peach-600",
  blue: "bg-blue-150 hover:bg-blue-100 active:bg-blue-100 text-white",
  "white-blue": "bg-white/10 hover:bg-white/20 active:bg-white/30 text-white",
  text: "hover:opacity-50 active:hover:opacity-80 focus:outline-none focus:ring-0",
  grey: "bg-grey-900 hover:bg-grey-900 active:bg-grey-900 text-black-ink",
  green:
    "bg-green-200 hover:bg-green-100 active:bg-green-300 text-white disabled:bg-green-200",
  "light-green":
    "bg-green-800 hover:bg-green-600/80 active:bg-green-600/90 text-green-200 disabled:bg-green-800 disabled:text-green-500",
  default:
    "bg-grey-950 hover:bg-action-900 active:bg-action-700 text-black-ink",
  outline:
    "text-grey-500 bg-transparent -m-[1px] border border-grey-900 active:border-grey-800",
};

type VariantNameType = keyof typeof variants;

// TODO: refactor to using just variant
const getVariantName = (
  primary?: boolean,
  dark?: boolean,
  white?: boolean,
  peach?: boolean,
  whitePeach?: boolean,
  variant?: VariantNameType
): VariantNameType => {
  if (primary) return "primary";
  if (dark) return "dark";
  if (white) return "white";
  if (peach) return "peach";
  if (whitePeach) return "whitePeach";
  return variant || "default";
};

export interface ButtonProps {
  id?: string;
  primary?: boolean;
  dark?: boolean;
  white?: boolean;
  peach?: boolean;
  whitePeach?: boolean;
  variant?: VariantNameType;
  small?: boolean;
  smaller?: boolean;
  large?: boolean;
  square?: boolean;
  circle?: boolean;
  children?: ReactNode;
  className?: string;
  isLoading?: boolean;
  component?: any;
  overrideColors?: boolean;
  textLeft?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  loadingClassName?: string;
  onClick?: MouseEventHandler;
  form?: string;
  type?: "button" | "submit";
  dataHeapEventName?: string;
}

const loadingSpinnerSizes = {
  default: 40,
  small: 30,
  smaller: 24,
};

const getLoadingSpinnerSize = (small?: boolean, smaller?: boolean) => {
  if (small) return loadingSpinnerSizes.small;
  if (smaller) return loadingSpinnerSizes.smaller;
  return loadingSpinnerSizes.default;
};

const ButtonComponent = (
  {
    primary,
    dark,
    white,
    peach,
    whitePeach,
    variant = "default",
    small,
    smaller,
    large,
    square,
    circle,
    children,
    className,
    isLoading,
    component: Component = "button",
    overrideColors,
    textLeft,
    disabled,
    icon,
    rightIcon,
    loadingClassName,
    type = "button",
    form,
    ...rest
  }: ButtonProps,
  ref
) => {
  const variantName = getVariantName(
    primary,
    dark,
    white,
    peach,
    whitePeach,
    variant
  );
  const loadingSpinnerSize = getLoadingSpinnerSize(small, smaller);
  return (
    <Component
      ref={ref}
      disabled={disabled || isLoading}
      className={twMerge(
        "inline-flex items-center leading-6 font-medium disabled:text-grey-800 focus:outline-none disabled:bg-grey-950 cursor-pointer disabled:cursor-default truncate relative",
        !textLeft && "justify-center",
        !overrideColors && variants[variantName],
        square && large && sizes.square.large,
        square && small && sizes.square.small,
        square && smaller && sizes.square.smaller,
        square && !small && !smaller && !large && sizes.square.medium,
        !square && large && sizes.default.large,
        !square && small && sizes.default.small,
        !square && smaller && sizes.default.smaller,
        !square && !small && !smaller && !large && sizes.default.medium,
        circle ? "rounded-full" : "rounded-md",
        small && "text-sm",
        smaller && "text-xs",
        className
      )}
      type={type}
      form={form}
      {...rest}
    >
      <span
        className={classNames("flex gap-1 items-center", {
          invisible: isLoading,
        })}
      >
        {icon}
        {children}
        {rightIcon}
      </span>
      {isLoading && (
        <span className={classNames("absolute", loadingClassName)}>
          <img
            src={"/images/spinner_web_96px_transparent.gif"}
            width={loadingSpinnerSize}
            height={loadingSpinnerSize}
            alt="Loading..."
            className="mx-auto"
          />
        </span>
      )}
    </Component>
  );
};

export const Button = forwardRef(ButtonComponent);
